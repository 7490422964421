import React from "react";
import AdminHeader from "../AdminHeader/AdminHeader";

const Admin = () => {
  return (
    <>
      <AdminHeader />
    </>
  );
};

export default Admin;
